import Swiper, { FreeMode, Navigation } from "swiper";
Swiper.use([FreeMode, Navigation]);
export const locationsSlider = () => {
  const locations = document.querySelector(".swiper.slider-locations");
  if (!locations) return;

  const swiper = new Swiper(locations, {
    grabCursor: true,
    slidesPerView: "auto",
    freeMode: true,
    navigation: {
      nextEl: ".arrow.button-next",
      prevEl: ".arrow.button-prev",
      disabledClass: "disabled",
    },
  });
};

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const onscrollAnimations = () => {
  let mm = gsap.matchMedia();
  let isMobile = window.innerWidth <= 1024;

  const sections = [...document.querySelectorAll("section")];
  const invertedSections = sections.filter((s) => s.dataset.inverted);

  const header = document.querySelector("header");
  const headerOffset = header.clientHeight / 2;

  const aside = document.querySelector("aside");
  const powered = document.querySelector(".powered.aside_powered");
  const socials = [...aside.querySelectorAll("a"), powered];
  const main = document.querySelector("main");
  const footer = document.querySelector("footer");

  // Invert header on scroll
  function toggleHeader() {
    invertedSections.length &&
      invertedSections.forEach((s) => {
        ScrollTrigger.create({
          trigger: s,
          start: `top ${headerOffset}px`,
          end: `bottom ${headerOffset}px`,
          onToggle: (e) => header.classList.toggle("inverted", e.isActive),
        });
      });
  }

  // Invert aside social icons on scroll
  function toggleAside() {
    const toggleSocialsColor = (e, icon) =>
      icon.classList.toggle("inverted", e.isActive);

    invertedSections.length &&
      invertedSections.forEach((s) => {
        socials.forEach((icon) => {
          const offset = icon.getBoundingClientRect();

          ScrollTrigger.create({
            trigger: s,
            start: `top ${offset.bottom}px`,
            end: `bottom ${offset.top}px`,
            // onToggle: e => toggleSocialsColor(e, icon),
            onUpdate: (e) => toggleSocialsColor(e, icon),
          });
        });
      });
  }

  // Hide aside/header on footer
  function hideOnFooter() {
    const tl = gsap
      .timeline({ paused: true })
      .to([header, aside, powered], { duration: 0.5, autoAlpha: 0 });

    ScrollTrigger.create({
      trigger: footer,
      onEnter() {
        tl.play();
      },
      onLeaveBack() {
        tl.reverse();
      },
    });
  }

  toggleHeader();
  !isMobile && toggleAside();
  hideOnFooter();
};

export { onscrollAnimations };

import { gsap } from "gsap/all";

class pointerParallax {
  constructor(target, options = {}) {
    this.target = target;
    this.wrapper = options?.wrapper || window;
    this.immediateStart = options?.immediateStart || false;
    this.items = this.target.querySelectorAll('[data-parallax]');
    this.items && this.init();
  }

  init() {
    this.fixer = -0.05;
    this.offsets = [];
    this.centerX = (this.wrapper !== window) ? this.wrapper.clientWidth / 2 : window.innerWidth / 2;
    this.centerY = (this.wrapper !== window) ? this.wrapper.clientHeight / 2 : window.innerHeight / 2;

    this.items.forEach(el => {
      this.offsets.push(JSON.parse(el.dataset.parallax));
    });

    this.move = this.move.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);

    this.wrapper.addEventListener('mouseenter', this.start);
    this.wrapper.addEventListener('mouseleave', this.stop);

    this.immediateStart && this.start;
  }

  start = () => {
    this.wrapper.addEventListener('mousemove', this.move);
  }

  stop = () => {
    this.wrapper.removeEventListener('mousemove', this.move);
  }

  move = (e) => {
    this.pointerX = (e.pageX - this.centerX) * this.fixer;
    this.pointerY = (e.pageY - this.centerY) * this.fixer;

    this.items.forEach((item, idx) => {

      let speedX = this.offsets[idx]?.x;
      let speedY = this.offsets[idx]?.y;

      gsap.to(item, {
        x: (this.pointerX * speedX),
        y: (this.pointerY * speedY)
      });
    });
  }
}

export { pointerParallax };
import { debounce, throttle } from "underscore";
import { gsap, Observer } from "gsap/all";
gsap.registerPlugin(Observer);

class scrollBar {

  constructor(options) {

    this.bodyScroll = !options?.scroller;
    this.scroller = this._getScroller(options?.scroller);
    this.wrapper = this._getWrapper();
    this.posY = this.scroller.scrollTop;
    this.prevPosY = this.scroller.scrollTop;
    this.isScrolling = false;
    this.isDraggable = options?.draggable;
    this.smoothScroll = !options?.smoothScroll ? false : {
      speed: options?.smoothScroll?.speed ?? 300,
      smooth: options?.smoothScroll?.smooth ?? 20,
    };
    this.isMobile = options?.isMobile ?? 1024;
    this.ignored = [...this.scroller.querySelectorAll('[data-scroll-ignore]')];

    this.initialized = false;
    this.isStarted = false;
    this.isDragging = false;
    this.isVisible = false;
    this.isLocked = false;
    this.direction = 'y';

    this.start = this.start.bind(this);
    this.pause = this.pause.bind(this);

    this.setup();
  }

  setup() {
    const refresh = debounce(() => {
      if (window.innerWidth > this.isMobile) {
        this.init();
      } else {
        this.kill();
      }
    }, 200);

    const resizeObserver = new ResizeObserver(entries => refresh());
    resizeObserver.observe(this.wrapper);
  }

  init() {
    this.initialized = true;
    this.wrapperHeight = (this.bodyScroll) ? window.innerHeight : this.wrapper.clientHeight;
    this.scrollerHeight = this.scroller.scrollHeight;

    if (!this.bar) {
      this.bar = document.createElement('div');
      this.track = document.createElement('div');
      this.bar.classList.add('scroll-bar');
      this.bar.style.position = this.bodyScroll ? 'fixed' : 'absolute';
      this.track.classList.add('scroll-track');
      this.bar.appendChild(this.track);
      this.wrapper.appendChild(this.bar);
    }

    if (this.scrollerHeight > this.wrapperHeight) {
      this.trackHeight = this.wrapperHeight / (this.scrollerHeight / this.wrapperHeight);
      this.realScrollLength = this.scrollerHeight - this.wrapperHeight;
      this.trackPath = this.bar.clientHeight - this.trackHeight;
      this.track.style.height = `${this.trackHeight}px`;

      this.wrapper.addEventListener('mouseenter', this.start);
      this.wrapper.addEventListener('mouseleave', this.pause);

      this.setTrackY = gsap.quickSetter(this.track, "y", "px");
      this.isDraggable && this.dragHandle();

      this.start();

      this.ignored.length && this.ignored.forEach(el => {
        el.onmouseenter = () => this.lockScroll();
        el.onmouseleave = () => this.unlockScroll();
      });
    }
  }

  dragHandle() {

    if (this.dragObserver) return;
    let pointerOnTrack;

    this.dragObserver = Observer.create({
      target: this.track,
      axis: "y",
      onPress: (self) => {
        this.wrapper.style.cursor = "grabbing";
        this.scroller.style.pointerEvents = "none";
        this.scroller.style.userSelect = "none";
        pointerOnTrack = self.event.offsetY;
      },
      onRelease: () => {
        this.wrapper.style.cursor = "auto";
        this.scroller.style.pointerEvents = "auto";
        this.scroller.style.userSelect = "auto";
      },
      onDragStart: (self) => {
        this.isDragging = true;
      },
      onDragEnd: () => {
        this.isDragging = false;
      },
      onDrag: (self) => {
        this.isDragging = true;
        this.isVisible = true;

        let trackY = Math.max(0, Math.min(self.event.clientY - pointerOnTrack, this.trackPath));
        let newPosY = trackY * this.realScrollLength / this.trackPath;

        this.setTrackY(trackY);
        this.posY = Math.max(0, Math.min(newPosY, this.realScrollLength));

        this.scroller.scrollTop = this.posY;
      }
    });
  }

  updateScroll = () => {

    if (!this.initialized) return;

    this.bar.classList.toggle('visible', this.isVisible);
    this.bar.classList.toggle('is-dragging', this.isDragging);

    // Move scrollbar track on scroll
    if (!this.isDragging) {
      let offset = this.trackPath * (this.scroller.scrollTop / this.realScrollLength);
      this.setTrackY(offset);
    }

    // Smooth scrolling
    if (this.smoothScroll && this.isScrolling) {

      // this.wrapper.classList.toggle('is-scrolling', this.isScrolling);
      this.scroller.scrollTop += (this.posY - this.scroller.scrollTop) / this.smoothScroll.smooth;

      if (this.prevPosY !== this.scroller.scrollTop) {
        this.prevPosY = this.scroller.scrollTop;
      } else {
        this.isScrolling = false;
      }
    }
  }

  scrollHandle = (e) => {

    if (this.isLocked || this.isDragging) {
      return;
    }

    this.isVisible = true;

    if (this.smoothScroll) {
      e.preventDefault();

      let newPosY = this.scroller.scrollTop + (-this._getWheelDelta(e) * this.smoothScroll.speed);
      this.posY = Math.max(0, Math.min(newPosY, this.realScrollLength));
      this.isScrolling = true;
    }

    if (!this.smoothScroll) {
      this.posY = this.scroller.scrollTop;
    }
  }

  start() {
    if (!this.isStarted && !this.isDragging) {
      this.isStarted = true;
      this.isVisible = true;

      if (this.observer) {
        this.observer.enable();
      } else {
        this.wrapper.addEventListener('wheel', this.scrollHandle, { passive: false });
        gsap.ticker.add(this.updateScroll);
      }
    }
  }

  pause() {
    if (this.isStarted && !this.isDragging) {
      this.isStarted = false;
      this.isVisible = false;
      this.bar.classList.remove('visible');

      if (this.observer) {
        this.observer.disable();
      } else {
        this.wrapper.removeEventListener('wheel', this.scrollHandle, { passive: false });
        gsap.ticker.remove(this.updateScroll);
      }
    }
  }

  kill() {

    if (!this.initialized) return;

    this.initialized = false;
    this.isVisible = false;
    this.isStarted = false;

    this.wrapper.removeEventListener('mouseleave', this.pause);
    this.wrapper.removeEventListener('mouseenter', this.start);

    if (this.observer) {
      this.observer.kill();
      this.observer = null;
    } else {
      gsap.ticker.remove(this.updateScroll);
      this.wrapper.removeEventListener('wheel', this.scrollHandle, { passive: false });
    }


    if (this.dragObserver) {
      this.dragObserver.kill();
      this.dragObserver = null;
    }

    if (this.bar) {
      this.bar.remove();
      this.bar = null;
    }
  }

  lockScroll() {
    this.isLocked = true;
  }

  unlockScroll() {
    this.isLocked = false;
  }

  on(event, callback) {

    const onScroll = () => {

      let info = {
        scrollTop: this.scroller.scrollTop,
        isScrolling: this.isScrolling
      }
      callback(info);
    }

    switch (event) {
      case 'scroll':
        gsap.ticker.add(onScroll);
      default:
        break;
    }
  }

  isActive() {
    return this.isStarted;
  }

  scrollTo(target) {

    let pos;

    switch (typeof target) {
      case 'object':
        pos = target.offsetTop;
        break;
      case 'string':
        pos = parseFloat(target);
        break;
      default:
        break;
    }

    this.scroller.scrollTop = pos;
  }

  _getScroller(scroller) {

    switch (typeof scroller) {
      case 'object':
        return scroller;
      case 'string':
        return document.querySelector(scroller);
      default:
        return document.scrollingElement
          || document.documentElement
          || document.body.parentNode
          || document.body;
    }
  }

  _getWrapper() {

    if (this.bodyScroll) {
      return (this.scroller === document.body && document.documentElement)
        ? document.documentElement
        : this.scroller; // safari is the new IE
    }

    return this.scroller.parentNode;
  }

  _getWheelDelta(e) {

    let delta;

    if (e.detail) {
      if (e.wheelDelta) {
        delta = e.wheelDelta / e.detail / 40 * (e.detail > 0 ? 1 : -1) // Opera
      }
      else {
        delta = -e.detail / 3 // Firefox
      }
    } else {
      delta = e.wheelDelta / 120 // IE,Safari,Chrome
    }

    // Normalize delta for touchpad
    // if (delta < 0.5) {
    //   delta = delta > -1 ? delta - 0.5 : delta;
    // }

    // if (delta > 0.5) {
    //   delta = delta < 1 ? delta + 0.5 : delta;
    // }

    return delta;
  }

}

export { scrollBar }
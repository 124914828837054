// Detect webp support
import 'webp-in-css/polyfill';

// Detect browser
import { detect } from 'detect-browser';

const browser = detect();

// Add browser to html class
document.getElementsByTagName("html")[0].classList.add(browser ? browser.name : "");

function _debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export { _debounce };
import Swiper, { Navigation } from "swiper";

Swiper.use([Navigation]);
export const experienceSlider = () => {
  const experience = document.querySelector(".swiper.experience-slider");
  if (!experience) return;
  const buttons = experience.querySelector(".buttons");
  const navs = buttons.querySelectorAll(".def.nav");

  let isTouch = false;
  let isMobile = window.innerWidth <= 1024;
  let video;

  const swiper = new Swiper(experience, {
    // allowTouchMove: false,
    slidesPerView: "auto",
    centeredSlides: true,
    grabCursor: true,
    disabledClass: "disabled",
    slideActiveClass: "active",
    slideNextClass: "next",
    slidePrevClass: "prev",
    loop: true,
    on: {
      slideChange: (e) => {
        if (!isMobile) return;
        video && video.pause();
        video = e.slides[e.activeIndex].querySelector("video");
        video && video.play();
      },
    },
    navigation: {
      nextEl: navs[0],
      prevEl: navs[1],
    },
    speed: 1500,
  });

  const hideButtons = () => {
    buttons.classList.add("hidden");
  };

  const showButtons = (sw) => {
    let w = sw.slides[sw.activeIndex].querySelector(".exp").clientWidth;
    buttons.style.width = `${w}px`;
    buttons.classList.remove("hidden");
  };

  if (isMobile) return;

  showButtons(swiper);

  swiper.on("slideChangeTransitionEnd", function (e) {
    if (!isTouch) showButtons(e);
  });
  swiper.on("slideChangeTransitionStart", function () {
    if (!isTouch) hideButtons();
  });
  swiper.on("transitionEnd", function (e) {
    if (isTouch) {
      showButtons(e);
      isTouch = false;
    }
  });
  swiper.on("touchMove", function () {
    setTimeout(() => {
      if (!isTouch) {
        isTouch = true;
        hideButtons();
      }
    }, 10);
  });
};

import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import { startScroll, stopScroll } from "../utils/scroll-config";


export const createVideo = () => {
  const videos = document.querySelectorAll(".createVideo");
  if (!videos.length) return;
  let isMobile = window.innerWidth <= 1024;
  const popup = document.querySelector(".pop-up.video");
  const close = popup.querySelectorAll(".close, .bg");

  const init = (video) => {
    let d = video.querySelector(".description");
    d = d ? d.textContent : false;
    let v = video.querySelector("video");
    let s = document.createElement("source");
    let src = video.dataset.src;
    let type = video.dataset.type;
    s.setAttribute("src", src);
    s.setAttribute("type", type);
    v.append(s);

    !isMobile && video.addEventListener("mouseenter", () => v.play());
    !isMobile && video.addEventListener("mouseleave", () => v.pause());
    video.addEventListener("click", () => openPopUp(src, d, type));
  };


  const openPopUp = (src, text, type) => {
    stopScroll();
    let v = popup.querySelector("video");
    let c = popup.querySelector(".content_video");
    let d = popup.querySelector(".description");

    if (v.dataset.video !== src) {
      text && (d.innerHTML = text);
      c.innerHTML = " ";
      v = document.createElement("video");
      let s = document.createElement("source");
      v.setAttribute("playsinline", "");
      s.setAttribute("src", src);
      s.setAttribute("type", type);
      v.append(s);
      c.append(v);
      v.dataset.video = src;
      v.addEventListener("click", () => {
        popup.classList.toggle("paused", !v.paused);
        v.paused ? v.play() : v.pause();
      });
    }
    setTimeout(() => popup.classList.add("active"), 300);
    document.addEventListener(
      "keydown",
      (e) => e.key === "Escape" && closePopUp()
    );
  };
  const closePopUp = (e) => {
    let v = popup.querySelector("video");
    popup.classList.remove("active");
    popup.classList.add("paused");
    startScroll();
    v.pause();
    document.removeEventListener("keydown", closePopUp);
  };

  videos.forEach((v) => {

    let section = v.closest('section');

    ScrollTrigger.create({
      trigger: section,
      // markers: true,
      once: true,
      onEnter() {
        init(v);
      }
    });
  });


  close.forEach((c) => c.addEventListener("click", closePopUp));
};

import gsap from "gsap";

export default function cookiesPopup() {
  const cookiesPopup = document.getElementById("cookiesPopUp");
  const accept = cookiesPopup.querySelector(".cookiesButton");

  const showCookies = gsap.timeline({
    paused: true,
    onReverseComplete: () => {
      showCookies.kill();
      sessionStorage.isVisited = "true";
      cookiesPopup.remove();
    }
  })
    .to(cookiesPopup, { duration: 0.5, autoAlpha: 1 })

  if (!sessionStorage.isVisited) {
    showCookies.play();
  } else {
    showCookies.kill();
    cookiesPopup.remove();
  }

  accept.addEventListener("click", () => {
    showCookies.reverse()
  });
}

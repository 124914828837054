import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export const communitySlider = () => {
  let isMobile = window.innerWidth <= 1024;
  const community = document.querySelector(".lineSlider");
  if (!community || isMobile) return;

  function initSlider() {
    const column = community.querySelector(".column");
    const row = community.querySelector(".row");
    const item = row.querySelectorAll(".slide");

    const progress = column.clientWidth;
    const windowW = window.innerWidth;
    let w = progress;

    function cloneElement() {
      item.forEach((i) => {
        let cl = i.cloneNode(true);
        cl.classList.add("clone");
        row.appendChild(cl);
      });
      w = column.clientWidth;
      changeWidth();
    }

    function changeWidth() {
      w < windowW * 0.8
        ? cloneElement()
        : infinitelyMove(column, row, progress);
    }

    changeWidth();
  }

  function infinitelyMove(elem, r, width) {
    let p = r.cloneNode(true);
    let n = r.cloneNode(true);
    elem.append(p, n);
    elem.classList.add("started");

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: elem,
        start: "top bottom",
        toggleActions: "play pause resume pause",
      },
    });
    tl.to(elem, {
      x: -width,
      repeat: -1,
      duration: Math.abs(width * 0.017),
      ease: "none",
    });
  }

  setTimeout(initSlider, 100);
};

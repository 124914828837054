import Swiper, { Navigation } from "swiper";

Swiper.use([Navigation]);
export const speakersSlider = () => {
  const speakers = document.querySelector(".swiper.speakers-slider");
  if (!speakers) return;
  const navs = speakers.querySelectorAll(".def.nav");

  const swiper = new Swiper(speakers, {
    // allowTouchMove: false,
    slidesPerView: "auto",
    on: {
      init: (e) => {
        // console.log(e);
        filterSlides(e);
        setTimeout(() => speakers.classList.remove("no-transition"), 100);
      },
    },
    centeredSlides: true,
    grabCursor: true,
    disabledClass: "disabled",
    slideActiveClass: "active",
    slideDuplicateClass: "duplicate",
    slideNextClass: "next",
    slidePrevClass: "prev",
    loop: true,
    navigation: {
      nextEl: navs[0],
      prevEl: navs[1],
    },
    speed: 1000,
  });
  swiper.on("slideChange", (s) => filterSlides(s));

  swiper.on("transitionEnd", (s) => {
    let isDuplicate = s.slides[s.activeIndex].classList.contains("duplicate");

    if (!isDuplicate) return;

    speakers.classList.add("no-transition");
    s.slideToLoop(s.realIndex, 0);
    setTimeout(() => speakers.classList.remove("no-transition"), 10);
  });

  function filterSlides(slider) {
    let idx = slider.activeIndex;
    let slides = slider.slides;
    slides.forEach((s, i) => {
      s.classList.toggle("slide-next", i > idx);
      s.classList.toggle("slide-prev", i < idx);
    });
  }
};

import { Loader } from "google-maps";

export default function Maps() {
  const g = document.getElementById("googleMaps");
  if (!g) return;

  const loader = new Loader(g.dataset.key);
  let mapStyle;
  let mapMarker = `${BAMBOO.assets}img/marker.svg`;

  fetchMapStyle();

  async function fetchMapStyle() {
    let response = await fetch(`${BAMBOO.assets}img/map/map-style.json`, {
      method: "GET",
      credentials: "same-origin",
    });

    if (response.ok) {
      mapStyle = await response.json();
      initMap(g);
    } else {
      console.log(response.status);
    }
  }

  function initMap(m) {
    loader.load().then(function (google) {
      let lt = m.dataset.lat;
      let lg = m.dataset.lng;
      const map = new google.maps.Map(m, {
        center: {
          lat: parseFloat(lt),
          lng: parseFloat(lg),
        },
        zoom: parseInt(m.dataset.zoom),
        disableDefaultUI: true,
        gestureHandling: "greedy",
        mapTypeControl: false,
        styles: mapStyle,
      });
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(lt, lg),
        icon: mapMarker,
        map: map,
      });
    });
  }
}

export const partnersLogo = () => {
  const logos = document.querySelectorAll(".scaleLogo .logo, .scaleLine .scale-node");
  if (!logos) return;

  logos.forEach((logo) => {
    let line = logo.querySelector(".line");
    let lg_w = logo.offsetWidth;
    let ln_w = line.offsetWidth;
    let scale = lg_w / (ln_w * 0.9);
    line.style.transform = `translate(-50%, -50%) scaleX(${scale})`
  });
};

import { onloadAnimations } from "./modules/animations-onload";
import { onscrollAnimations } from "./modules/animations-onscroll";
import { timelineAnimations } from "./modules/animations-timeline";
import { createVideo } from "./modules/createVideo";
import Form from "./modules/form";
import Maps from "./modules/google-maps";
import { partnersLogo } from "./modules/partners-logos";
import questionnairesForms from "./modules/questionnaires";
import { communitySlider } from "./modules/slider-community";
import { eventsSlider } from "./modules/slider-events";
import { experienceSlider } from "./modules/slider-experience";
import { locationsSlider } from "./modules/slider-locations";
import { speakersSlider } from "./modules/slider-speakers";
import toggleMenu from "./modules/toogle-menu";
import "./utils/index";

onloadAnimations();
onscrollAnimations();
timelineAnimations();
toggleMenu();
partnersLogo();
questionnairesForms();
createVideo();
Maps();
speakersSlider();
locationsSlider();
eventsSlider();
experienceSlider();
Form();
communitySlider();



// let svgs = document.querySelectorAll(".community .logo .line svg path");
// console.log(svgs);
// svgs.forEach((s) => {
//   console.log(s.getTotalLength());
// });

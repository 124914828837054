export default function questionnairesForms() {
  const q = document.getElementById("questionnairesForms");
  if (!q) return;

  const tabs = q.querySelectorAll(".tabs .type");
  const line = q.querySelector(".line span");
  const forms = q.querySelectorAll(".form_content");

  let hash = window.location.hash;
  let t = hash
    ? [...tabs].find((t) => t.dataset.hash == hash.split("#").pop())
    : tabs[0];

  const hashChange = (tab) => {
    window.location.hash = tab.dataset.hash;
    line.style.left = `${tab.offsetLeft}px`;
    line.style.width = `${tab.offsetWidth}px`;
    tabs.forEach((t) => t.classList.toggle("active", tab == t));
    [...forms].forEach((f) =>
      f.classList.toggle("active", f.classList.contains(tab.dataset.hash))
    );
  };

  tabs.forEach((tab) => tab.addEventListener("click", (e) => hashChange(tab)));
  hashChange(t);
}

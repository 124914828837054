import gsap from 'gsap';
import baffle from 'baffle';
import Splitting from "splitting";
import cookiesPopup from './cookies';
import { stopScroll, startScroll } from "../utils/scroll-config";
import { pointerParallax } from '../utils/pointer-parallax';

const onloadAnimations = () => {

  preLoader();

  function preLoader() {
    const preloader = document.querySelector('.preloader');
    if (!preloader) return;

    stopScroll();

    const preloaderBg = preloader.querySelector('.preloader-bg');
    const preloaderLogo = preloader.querySelector('.preloader-logo');
    const svg = preloaderLogo.querySelector('svg');
    const sh3 = preloaderLogo.querySelector('.sh3');
    const sh3Split = Splitting({ target: sh3, by: 'chars' });

    const shuffled = {};

    const startShuffleText = () => {

      sh3Split[0].chars.forEach((el, idx) => {
        shuffled[idx] = baffle(el, {
          characters: 'abcdefghijklmnopqrstuvwxyz',
        });
        shuffled[idx].start();
      });

    }

    const revealText = idx => {
      gsap.to(sh3Split[0].chars[idx], {
        duration: 1, autoAlpha: 1, ease: "none", onComplete: () => {
          shuffled[idx].reveal();
        }
      })
    }

    const tl = gsap.timeline({
      onComplete: () => {
        cookiesPopup();
        tl.kill();
        preloader.remove();
        startScroll();
      }
    })
      .to(preloaderBg, { duration: 5, autoAlpha: 0.9, ease: "none" }, "preload")
      .call(startShuffleText, null, "preload")
      .set(sh3, { autoAlpha: 1 }, "preload")
      .to(svg, { duration: 1, autoAlpha: 1, ease: "none" }, "preload")
      .call(revealText, [0], "preload")
      .call(revealText, [1], "preload+=0.75")
      .call(revealText, [2], "preload+=1.5")
      .to([preloaderLogo, preloader], { delay: 1, autoAlpha: 0, stagger: 0.25 }, "preload+=2")


    // const textPreloader = () => {
    //   const text = preloader.querySelector('.preloader-text');

    //   const web = text.querySelector('.web');
    //   const sh3 = text.querySelector('.sh3');



    //   const webSplit = Splitting({ target: web, by: 'chars' });
    //   // const webShuffle = baffle(web);

    //   const sh3Split = Splitting({ target: sh3, by: 'chars' });
    //   // const sh3Shuffle = baffle(sh3);

    //   console.log(sh3Split[0].chars);

    //   const showLogo = gsap.timeline();

    //   const startShuffleText = () => {

    //     sh3Split[0].chars.forEach(el => {


    //       let shuffle = baffle(el);
    //       shuffle.start()

    //     });

    //   }

    //   // startShuffleText();

    //   // showLogo
    //   //   .call(() => {
    //   //     webShuffle.start()
    //   //     sh3Shuffle.start();
    //   //   }, null)
    //   //   .to([web, sh3], { duration: 0.5, autoAlpha: 1, ease: "none" })
    //   //   .call(() => {
    //   //     webShuffle.reveal();
    //   //     sh3Shuffle.reveal();
    //   //   }, null, "+=2")
    //   //   .to([text, preloader], { autoAlpha: 0, stagger: 0.3, ease: "none" })
    // }
  }

  const welcomeHero = document.getElementById('homeVisual');
  if (welcomeHero) {
    const moveHero = new pointerParallax(welcomeHero, {
      wrapper: welcomeHero.closest('section'),
      immediateStart: true
    })
  }

}

export { onloadAnimations }
import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const timelineAnimations = () => {
  const agenda = document.getElementById("agendaTimeline");
  if (!agenda) return;

  const dateNavs = agenda.querySelectorAll('.agenda-nav button');
  const timeLines = agenda.querySelectorAll('.timeline');

  function scrollTimeline(timeline) {
    const scroller = timeline.querySelector(".descriptions");

    const navs = [...timeline.querySelectorAll(".navs button")];
    const descs = [...scroller.querySelectorAll(".description")];

    const toggleActive = (active) => {
      navs.forEach((nav, idx) => {
        nav.classList.toggle("active", idx === active);
        descs[idx].classList.toggle("active", idx === active);
      });
    };

    if (navs.length) {
      if (descs.length) {
        const lastItem = descs[descs.length - 1] ?? descs[0];
        const wrapperHeight = scroller.clientHeight;
        const lastItemHeight = lastItem.clientHeight;
        scroller.style.paddingBottom = `${wrapperHeight - lastItemHeight}px`;
      }

      navs.forEach((nav, idx) => {
        nav.addEventListener("click", () => {
          gsap.to(scroller, {
            duration: 0.75, scrollTo: descs[idx], onComplete: () => {
              toggleActive(idx);
            }
          });
        });

        // ScrollTrigger.create({
        //   trigger: descs[idx],
        //   scroller: scroller,
        //   start: "5% 10%",
        //   // markers: true,
        //   // end: "top bottom",
        //   onToggle: (e) => {
        //     e.isActive && toggleActive(idx);
        //   },
        // });
      });
    }
  }

  function toggleDate(idx) {
    dateNavs.forEach((el, elIdx) => {
      el.classList.toggle('active', elIdx === idx);
    })
    timeLines.forEach((el, elIdx) => {
      el.classList.toggle('active', elIdx === idx);
    })
  }

  dateNavs.length && dateNavs.forEach((button, idx) => {
    button.addEventListener('click', () => {
      toggleDate(idx);
    })
  });

  timeLines.length && timeLines.forEach(el => scrollTimeline(el));
};

export { timelineAnimations };

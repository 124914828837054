import Swiper, { Navigation } from "swiper";

Swiper.use([Navigation]);
export const eventsSlider = () => {
  const events = document.querySelector(".swiper.events-slider");
  if (!events) return;
  const navs = events.querySelectorAll(".blue.nav");

  const swiper = new Swiper(events, {
    // allowTouchMove: false,
    slidesPerView: "auto",
    centeredSlides: true,
    grabCursor: true,
    disabledClass: "disabled",
    slideActiveClass: "active",
    slideNextClass: "next",
    slidePrevClass: "prev",
    navigation: {
      nextEl: navs[0],
      prevEl: navs[1],
    },
    speed: 500,
  });
};

import { startScroll, stopScroll } from "../utils/scroll-config";

export default function toggleMenu() {
  const header = document.querySelector("header");
  const burger = header.querySelector(".mobile_btn");
  const dropdowns = header.querySelectorAll(".dropdown");

  const openMenu = () => {
    stopScroll();
    header.classList.add("active");
  };
  const closeMenu = () => {
    startScroll();
    header.classList.remove("active");
  };

  burger.addEventListener("click", () => {
    !header.classList.contains("active") ? openMenu() : closeMenu();
  });

  dropdowns.forEach((dropdown, d) => {
    let children = dropdown.children[0]
    children.addEventListener("click", (e) => {
      e.preventDefault();
      let active = children.classList.contains("opened");
      if (active) {
        children.classList.toggle("opened", !active);
      } else {
        dropdowns.forEach((el, x) => {
          let c = el.children[0]
          c.classList.toggle("opened", d == x);
        });
      }
    });
  });
}

import autosize from "autosize";
import { startScroll, stopScroll } from "../utils/scroll-config";
import gsap from "gsap";

export default function Form() {
  const content = document.querySelectorAll(".form_content");

  if (!content.length) return;

  const popups = document.querySelectorAll(".pop-up");
  const successPopUp = [...popups].find((p) => p.classList.contains("success"));
  const partnerPopUp = [...popups].find((p) =>
    p.classList.contains("partner-pop-up")
  );
  const billingPopUp = [...popups].find((p) =>
    p.classList.contains("billing-pop-up")
  );
  const openPartner = document.querySelectorAll("[data-open-partner]");
  const openBilling = document.querySelectorAll("[data-open-billing]");

  const closePopUp = document.querySelectorAll(".close_btn");

  const textarea = [...document.querySelectorAll("textarea")];
  let list = document.querySelector(".events-list");

  autosize(textarea);

  // Form functions
  function useForm(c) {
    createLoader(c);

    let formState = {};
    const form = c.querySelector("form");
    const isStepsForm = form.querySelector(".fieldset-cf7mls-wrapper");
    const method = form.method;
    const inputs = [...form.elements].filter(
      (i) =>
        i.type !== "submit" &&
        i.type !== "hidden" &&
        i.type !== "fieldset" &&
        i.type !== "button"
    );
    const fileInput = inputs.filter((i) => i.type == "file")[0];
    const submit = c.querySelector("button.submit");
    const loader = c.querySelector(".loader-squares");
    const squares = loader.querySelectorAll(".s");
    let eventLabel = form.querySelector(".select-event");

    // let eventLabel = form.querySelector(".select-event");

    let formResponse = false;
    let isFetching = false;

    const tl = gsap.timeline({
      yoyo: true,
      paused: true,
      repeat: 1,
      // repeatDelay: 2,
      onComplete: (e) => {
        if (!isFetching && formResponse) {
          onValidate(formResponse);
        } else {
          tl.restart();
        }
      },
    });

    tl.from([squares], { duration: 0.5, x: 0, y: 0, opacity: 1, delay: 0.3 });

    const labels = inputs.map((i) => i.closest("label")).filter((i) => i);
    let current_fs, next_fs, previous_fs; //fieldsets
    let stepIndex = 1;
    let cont, max_size, fileInfo, fileName, fileRemove, accept;

    if (fileInput) {
      cont = c.querySelector(".add-file");
      const dropArea = fileInput.parentNode;
      // addFile = cont.querySelector(".add_file_btn");
      // types = [];
      max_size = fileInput.getAttribute("size") / 1024 / 1024;
      fileInfo = cont.querySelector(".file-info");
      fileName = fileInfo.querySelector(".file-name");
      fileRemove = fileInfo.querySelector(".file-remove");
      accept = fileInput.getAttribute("accept");

      accept && fileInput.setAttribute("accept", accept.split("|").join(","));

      // fileRemove.addEventListener("click", removeFile);
      fileInput.addEventListener("change", addFileName);
      // addFile.addEventListener("click", (e) => {
      //   if (fileInfo.querySelector("span"))
      //     fileInfo.querySelector("span").remove();
      //   removeFile();
      //   fileInput.click();
      // });

      fileInput.addEventListener("dragenter", highlight);

      ["dragleave", "drop"].forEach((eventName) => {
        fileInput.addEventListener(eventName, unHighlight);
      });

      function highlight(e) {
        dropArea.classList.add("highlight");
      }
      function unHighlight(e) {
        dropArea.classList.remove("highlight");
      }
    }

    function removeFile() {
      // e.preventDefault();
      fileName.textContent = "";
      // cont.classList.remove("visible", "all");
      fileInput.value = "";
    }

    function addFileName(e) {
      fileName.textContent = "";

      if (fileInput.files.length == 0) {
        removeFile();
        return;
      }
      let file = fileInput.files[0];
      let size = file.size / 1024 / 1024;
      let i = file.name.split(".");
      // let f = document.createElement("div");

      size = size > 1 ? size.toFixed() : size.toFixed(2);
      // f.classList.add("file");

      fileName.innerHTML = `<div class="name">${i[0]}</div>.<div class="type">${i[1]}</div><div class="size">/${size}mb</div><div class="remove"></div>`;
      let remove =
        fileName.lastElementChild || fileName.querySelector(".remove");
      remove.addEventListener("click", removeFile);

      // let ext = "." + file.split(".").pop();

      if (fileInfo.querySelector("span"))
        fileInfo.querySelector("span").remove();

      // Size error
      // if (size.toFixed(2) > max_size) {
      //   errorFile(`Max file size ${max_size.toFixed()} MB`);
      // }

      // Extension error
      // if (!types.includes(ext)) {
      //   errorFile("This type of file is not allowed");
      //   return;
      // }
      // cont.classList.add("visible");
    }

    // function errorFile(msg) {
    //   if (fileInfo.querySelector("span"))
    //     fileInfo.querySelector("span").remove();
    //   let s = document.createElement("span");
    //   s.classList.add("file-error");
    //   s.textContent = msg;

    //   fileInfo.appendChild(s);
    //   setTimeout(removeFile, 10);
    // }

    // On validate
    function onValidate(data) {
      c.classList.remove("loading");
      switch (data.status) {
        case "validation_failed":
          onValidateError(data.invalid_fields);
          break;
        case "mail_sent":
          onSuccess(data.message);
          break;
        case "mail_error":
        case "mail_failed":
          onServerError(data);
          break;
        default:
          console.log("default");
      }
    }

    // Before request
    const beforeSend = () => {
      // Remove class 'error' from label
      labels.forEach((label) => {
        // let t = label.querySelector(".tip");
        // t && (t.innerHTML = "");
        label.classList.remove("error");
      });

      // Lock submit button to prevent multiple clicks
      submit.disabled = true;

      c.classList.add("loading");
    };

    // After successful request
    const onSuccess = (message) => {
      //Show message
      // successPopUp.querySelector(".title").innerHTML = title;
      successPopUp.querySelector(".message").innerHTML = message;

      //Open pop up & Disable Body scroll
      showPopUp();

      // Reset form
      inputs.forEach((input) => {
        input.closest("label").classList.remove("active");

        if (input.type == "checkbox") {
          input.checked = false;
          return;
        }
        if (input.type == "radio") return;
        if (input.closest("label.select-event")) return;

        input.value = "";
        input.setAttribute("value", "");
      });

      //Hide file info
      fileInput && removeFile();

      //Go to first form step
      isStepsForm && goToFirstStep();

      // Update autosize
      setTimeout((e) => autosize.update(textarea));
    };

    // Form validation error
    const onValidateError = (errors) => {
      // submit.disabled = false;

      errors.forEach((error) => {
        let input = inputs.filter((i) => i.id === error.idref);

        // if (input[0].type == "file") {
        //   removeFile();
        //   // errorFile(error.message);
        //   // return;
        // }

        let label = input[0].closest("label");
        let t = label.querySelector(".tip");
        label && label.classList.add("error");
        t && (t.innerHTML = error.message);
      });
    };

    // Server error
    const onServerError = (data) => {
      onSuccess(data.message);
      successPopUp.classList.add("error");
    };

    // Send form via AJAX
    const sendForm = () => {
      // if (isFetching) return;
      // Before request send
      beforeSend();
      tl.restart();

      isFetching = true;

      // if (fileInput && !fileInput.files.length) {
      //   errorFile("You need add file");
      //   submit.disabled = false;
      //   return;
      // }

      // Form data
      let data = new FormData(form);
      let url = `${BAMBOO.baseUrl}/wp-json/contact-form-7/v1/contact-forms/${form.id}/feedback`;
      data.append("action", "submit_ccform");

      // Send request
      fetch(url, {
        method: method,
        credentials: "same-origin",
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          // c.classList.remove("loading");
          submit.disabled = false;
          formResponse = data;
          isFetching = false;
        })
        // Fetch error
        .catch((err) => {
          onServerError(err);
        });
    };

    const innerTextToInput = (label, value) => {
      let input = label.querySelector(".hidden");
      input.value = value ? value : "";
    };

    const goToFirstStep = () => {
      let fields = isStepsForm.querySelectorAll("fieldset");
      let step = isStepsForm.querySelector(".active-step");

      stepIndex = 1;
      step.innerHTML = stepIndex;
      fields.forEach((f, i) => f.classList.toggle("cf7mls_current_fs", i == 0));
      current_fs = fields[0];
      next_fs = current_fs.nextElementSibling;
    };

    inputs.forEach((input) => {
      input.addEventListener("input", () => {
        let label = input.closest("label");
        let err = label.classList.contains("error");
        if (err) label.classList.remove("error");
        if (input.type == "file") return;

        let v = input.value;
        let name = input.name
          ? input.name
          : label.querySelector("input[name]").name;

        formState = {
          ...formState,
          [name]: v,
        };
        input.classList.contains("pseudo") && innerTextToInput(label, v);

        label.classList.toggle("active", v);
      });
    });

    // Submit form on click
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      // sendForm();
    });

    function initStepsForm(form) {
      // let cf7mls_object = {
      //   ajax_url: BAMBOO.ajaxUrl,
      //   is_rtl: "",
      //   disable_submit: "true",
      //   cf7mls_error_message: "",
      //   scroll_step: "true",
      //   disable_enter_key: "false",
      //   check_step_before_submit: "true",
      // };

      let next_clicked = false;
      let findFieldset = form.querySelectorAll("fieldset");
      let allSteps = form.querySelector(".fieldset-cf7mls-wrapper");
      let btn = form.querySelectorAll(".cf7mls_btn");

      let nextStep = form.querySelectorAll(".cf7mls_next");
      let backStep = form.querySelectorAll(".cf7mls_back");

      btn.forEach((b) => b.querySelector("img").remove());

      if (findFieldset.length > 0) {
        let d = document.createElement("div");
        d.classList.add("all-steps-num");
        d.innerHTML = ` <span class="active-step">${stepIndex}</span>/${findFieldset.length}`;
        allSteps.append(d);
        findFieldset[0].classList.add("cf7mls_current_fs");
      }

      nextStep.forEach((button) => {
        button.addEventListener("click", (e) => {
          if (next_clicked) return;

          isFetching = true;
          tl.restart();
          c.classList.add("loading");
          next_clicked = true;
          current_fs = button.closest(".fieldset-cf7mls");
          let inputs = current_fs.querySelectorAll(".input_row");
          next_fs = current_fs.nextElementSibling;

          inputs.forEach((i) => i.classList.remove("error"));

          let fd = new FormData(form);
          fd.append("action", "cf7mls_validation");

          fetch(BAMBOO.ajaxUrl, {
            method: "POST",
            credentials: "same-origin",
            body: fd,
          })
            .then((response) => response.json())
            .then((data) => {
              let json = data;

              const onResponse = () => {
                if (tl.progress() < 0.01) {
                  tl.pause();
                  c.classList.remove("loading");
                  form.scrollIntoView();
                  // console.log(form);

                  if (!json.success) {
                    let checkError = 0;
                    let keys = Object.keys(json.invalid_fields);

                    keys.forEach((index) => {
                      let label = current_fs.querySelector(`[name="${index}"`);

                      if (label) {
                        checkError = checkError + 1;
                        let parent = label.closest("label");
                        let tip = parent.querySelector(".tip");
                        parent.classList.add("error");
                        tip &&
                          (tip.innerHTML = json.invalid_fields[index].reason);

                        // return false;
                      }
                    });

                    if (checkError == 0) {
                      json.success = true;
                    }
                  }

                  if (json.success) {
                    stepIndex++;
                    form.querySelector(".active-step").innerHTML = stepIndex;
                    // $(form).find(".active-step").html(stepIndex);
                    current_fs.classList.remove("cf7mls_current_fs");
                    current_fs.classList.add("cf7mls_back_fs");
                    next_fs.classList.add("cf7mls_current_fs");
                  }
                  next_clicked = false;
                  gsap.ticker.remove(onResponse);
                }
              };

              gsap.ticker.add(onResponse);
            })
            .catch((err) => {});

          return false;
        });
      });

      backStep.forEach((button) => {
        button.addEventListener("click", (e) => {
          stepIndex--;
          form.querySelector(".active-step").innerHTML = stepIndex;

          current_fs = button.closest(".fieldset-cf7mls");
          previous_fs = current_fs.previousElementSibling;
          current_fs.classList.remove("cf7mls_current_fs");
          previous_fs.classList.remove("cf7mls_back_fs");
          previous_fs.classList.add("cf7mls_current_fs");

          current_fs
            .querySelectorAll(".error")
            .forEach((er) => er.classList.remove("error"));
          return false;
        });
      });
    }

    function selectEvent() {
      let input = eventLabel.querySelector("input");

      if (!list) {
        eventLabel.style.display = "none";
        input.value = "Not Active Events";

        return;
      }

      let active = eventLabel.querySelector(".active");
      let block = eventLabel.querySelector(".past-all-events");
      block.append(list.cloneNode(true));

      let events = eventLabel.querySelectorAll(".events-list .event");

      active.addEventListener("click", (e) => {
        eventLabel.classList.contains("open") ? closeSelect() : openSelect();
      });

      events.forEach((ev) => ev.addEventListener("click", (e) => select(ev)));

      function select(event) {
        if (event.classList.contains("selected")) return;
        events.forEach((ev) => ev.classList.toggle("selected", event == ev));
        active.innerHTML = "";
        active.append(event.cloneNode(true));
        closeSelect();
        input.value = event.querySelector(".title").textContent;
      }
      function openSelect() {
        eventLabel.classList.add("open");
        document.addEventListener("keydown", hideOnEscape);
      }
      function closeSelect(e) {
        eventLabel.classList.remove("open");
        document.removeEventListener("keydown", hideOnEscape);
      }

      // Close PopUp on Escape keydown
      function hideOnEscape(e) {
        if (e.key === "Escape") closeSelect();
      }

      select(events[0]);
    }

    eventLabel && selectEvent();
    isStepsForm && initStepsForm(form);
    submit.addEventListener("click", (e) => {
      sendForm();
    });
  }

  function showPopUp(p = successPopUp) {
    stopScroll();
    p.classList.add("active");
    document.addEventListener("keydown", hideOnEscape);
    document.addEventListener("click", hideOnOutside);
  }

  function hidePopUp() {
    startScroll();
    popups.forEach((p) => p.classList.remove("active"));
    document.removeEventListener("keydown", hideOnEscape);
    document.removeEventListener("click", hideOnOutside);

    setTimeout(() => {
      successPopUp.classList.remove("error");
    }, 400);
  }

  function createLoader(form) {
    let p = document.createElement("div");
    p.classList.add("loader-squares");
    p.innerHTML = `
    <div class='s'></div>
    <div class='s'></div>
    <div class='s'></div>
    <div class='s'></div>
    `;
    form.append(p);
  }

  // Close PopUp on Escape keydown
  function hideOnEscape(e) {
    if (e.key === "Escape") hidePopUp();
  }

  // Close PopUp on click outside Popup
  function hideOnOutside(e) {
    if (e.target.id == "pop-up") hidePopUp();
  }

  [...content].forEach((c) => useForm(c));
  [...closePopUp].forEach((b) => b.addEventListener("click", hidePopUp));
  [...openPartner].forEach((b) =>
    b.addEventListener("click", (e) => {
      let h = b.href.split("#");
      if (h.length < 2) return;
      e.preventDefault();
      showPopUp(partnerPopUp);
    })
  );
  [...openBilling].forEach((b) =>
    b.addEventListener("click", (e) => {
      let h = b.href.split("#");
      if (h.length < 2) return;
      e.preventDefault();
      showPopUp(billingPopUp);
    })
  );
}
